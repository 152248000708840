var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('order-create'),_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"absolute":"","dark":"","fab":"","top":"","right":"","small":"","color":"blue"},on:{"click":_vm.refreshData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{staticClass:"mb-1",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$store.dispatch('openInstanceCreateDialog')}}},[_vm._v(_vm._s(_vm.$t('instances.actions.new')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('actions.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.items)?_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.config.limits_memory",fn:function(ref){
var item = ref.item;
return [(item.config.limits_memory)?_c('span',[_vm._v(_vm._s(_vm.getMemoryConfig(item)[0])+" "+_vm._s(_vm.getMemoryConfig(item)[1]))]):_vm._e()]}},{key:"item.config.limits_disk",fn:function(ref){
var item = ref.item;
return [(item.config.limits_disk)?_c('span',[_vm._v(_vm._s(_vm.getStorageConfig(item))+" "+_vm._s(_vm.getStorage.limits_unit_show))]):_vm._e()]}},{key:"item.ips[0].address",fn:function(ref){
var item = ref.item;
return [(item.ips[0])?_c('span',[_vm._v(_vm._s(_vm.filterIpv6(item.ips[0])))]):_vm._e()]}},{key:"item.ips[1].address",fn:function(ref){
var item = ref.item;
return [(item.ips[1])?_c('span',[_vm._v(_vm._s(_vm.filterIpv6(item.ips[1])))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","left":"","to":'/instance/'+item.id}},[_vm._v(_vm._s(_vm.$t('instances.actions.manage')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.colorstate(item.status)}},[_vm._v(_vm._s(_vm.iconstate(item.status)))])]}}],null,false,1565252195)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }