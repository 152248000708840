<template>
  <div>
    <instances></instances>
  </div>
</template>

<script>
  import Instances from '../../components/Instances/Instances';
  // import Containers from '../../components/Containers/Containers';

  export default {
    name: 'instance',
    components: {
      instances: Instances
    },
    data() {
      return {

      };
    }
  };
</script>
